/**
 * Configure require
 */
require.config({
    paths: {
        "UiBuildingBlocks": "../lib/UiBuildingBlocks",
        "pako": "../lib/pako/pako",
        "digital-twin": "..",
        "auth0-js": "../lib/auth0-js/auth0",
        "crypto-js": "../lib/crypto-js/crypto-js",
        "signalR": "../lib/signalr/signalr",
        "signalR-msgpack": "../lib/signalr/signalr-protocol-msgpack",
        "msgpack5": "../lib/msgpack5/msgpack5",
        "serv": "../lib/serv/umd/servu",
        "echarts": "../lib/echarts/echarts"         
    }
});

/**
 * Load App
 */
require(["digital-twin/startup/app"]);